import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
// Utils
import stringUtils from 'js/react/_utils/stringUtils';

// Components
import { useRequestWithPromise } from 'src/core/api';
import { fetchPlaylist, getPlaylistById } from 'src/entities/playlists';
import { useSelector } from 'react-redux';
import TabView from 'js/react/components/TabView';
import { ApiErrorMessage } from 'src/components/Error';
import convertType2Icon from '../utils';
import { PlaylistPlaces } from '../PlaylistPlaces';
import { EditPlaylist } from '../EditPlaylist';

const propTypes = {
  id: PropTypes.string.isRequired,
  tab: PropTypes.oneOf(['edit', 'places']),
};

export const PlaylistSidebar = ({ tab = 'edit', id: playlistId }) => {
  const { dispatch, error } = useRequestWithPromise();
  const { formatMessage } = useIntl();
  const playlist = useSelector(getPlaylistById(playlistId));
  const subType = playlist?.subType;

  useEffect(() => {
    dispatch(fetchPlaylist(playlistId));
  }, []);

  if (error) {
    return (
      <ApiErrorMessage error={error} className="m-2" />
    );
  }

  return !playlist ? null : (
    <div className="holder bg-white p-3">
      {/* <div className="m-2 card bshadow" style={{display: 'block'}}> */}
      <div className="module_head">
        <div>
          <a
            type="a"
            aria-label="Close"
            className="close"
            href="#/playlists"
          >
            <span aria-hidden="true">
              {'\u00D7'}
            </span>
          </a>
        </div>
        <div className="tabs" />
        <h3>
          <span>{playlist.name}</span>
        </h3>
        <p>
          {convertType2Icon(playlist.type)}&nbsp;
          <span className="prepend-tabmodule-title">
            {stringUtils.capitalize(playlist.subType)}
          </span>
        </p>
      </div>
      <TabView
        className="mb-3"
        defaultTab={tab}
        tabs={[{
          id: 'edit',
          label: formatMessage({
            id: 'common.edit',
          }),
          href: `#/playlists/${playlistId}/edit`,
          'data-testid': 'playlist-edit-tab',
        }, subType !== 'default' && subType !== 'filler' && {
          id: 'places',
          label: formatMessage({
            id: 'common.places',
          }),
          href: `#/playlists/${playlistId}/places`,
          'data-testid': 'playlist-places-tab',
        }]}
      >
        {(active) => {
          switch (active) {
          case 'places':
            return <PlaylistPlaces playlistId={playlistId} />;
          default:
            return <EditPlaylist playlistId={playlistId} />;
          }
        }}
      </TabView>
    </div>
  );
};

PlaylistSidebar.propTypes = propTypes;
