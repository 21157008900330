import React from 'react';
import t from 'translation';
import format from 'js/react/_utils/format';
import cancan from 'js/system/cancan';
import { PLANNING_TIMEPLAN } from 'constants/permissions';
import { FormattedMessage, useIntl } from 'react-intl';
import { updatePlaylist } from 'src/entities/playlists';
import { useRequestWithPromise } from 'src/core/api';

export const InfoBox = ({ playlist }) => {
  const { dispatch } = useRequestWithPromise();
  const { formatMessage } = useIntl();
  let category = (playlist && playlist.category) || 'none';

  const handleUpdatePlaylist = (data) => {
    dispatch(updatePlaylist(data));
  };

  // handle legacy category "info"
  if (category === 'info') {
    category = 'none';
  }

  return (
    <div className="row">
      <div className="col-12 col-md-6">
        <table>
          <tbody>
            <tr>
              <td style={{ width: 100 }}>
                <small className="mr-3">
                  <FormattedMessage id="common.type" />
                  {': '}
                </small>
              </td>
              <td style={{ width: 100 }}>
                <b>{t[playlist.type]}</b>{' '}
                <i className={format.playlist.typeIcon(playlist.type)} />
              </td>
            </tr>
            <tr>
              <td style={{ width: 100 }}>
                <small className="mr-3">
                  <FormattedMessage id="common.subtype" />
                </small>
              </td>
              <td style={{ width: 100 }}>
                <b>{t[playlist.subType]}</b>
              </td>
            </tr>
            {cancan.can('read', PLANNING_TIMEPLAN) && (
              <tr>
                <td style={{ width: 100 }}>
                  <small className="mr-3">
                    {t.max_time}
                    {': '}
                  </small>
                </td>
                <td style={{ width: 100 }}>
                  <b>{format.formatSeconds(playlist.max_time)}</b>
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
      <div className="col-12 col-md-6">
        <small>
          <FormattedMessage id="common.description" />
          {':'}
        </small>
        <br />
        <em>{playlist.description}</em>
      </div>
    </div>
  );
};
