import React from 'react';
import { compose } from 'redux';
import { connect, useSelector } from 'react-redux';
import moment from 'moment';
import cancan from 'js/system/cancan';
import format from 'js/react/_utils/format';
import { bulkPlanMedia, copyFromInterval } from 'src/entities/plannedMedia';
import { PLANNING_TIMEPLAN } from 'constants/permissions';
import { getMediaInObject } from 'src/entities/media';
import TemplateDrop, { usePlDrop } from './template-drop';

function renderTemplates(item, {
  templates, isOver, canDrop, dropMedia, planLocked,
}) {
  return templates.map((_t) => {
    return (
      <TemplateDrop
        key={_t.id}
        template={_t}
        max={item.get('maxTime')}
        planLocked={planLocked}
        className={isOver && canDrop ? ' active' : ''}
        dropMedia={dropMedia}
      />
    );
  });
}

const PlaylistRow = ({
  item,
  copyLastWeek,
  fromDate,
  planLocked,
  templates,
  dropMedia,
  playlistId,
}) => {
  const id = item.get('id');
  const type = item.get('type');
  const allMedia = useSelector(getMediaInObject);

  const handleDrop = (mediaIds, template) => {
    return dropMedia(
      mediaIds?.map((mid) => allMedia[mid]),
      playlistId,
      template,
    );
  };

  const [{ isOver, canDrop }, drop] = usePlDrop({
    planLocked,
    dropMedia: handleDrop,
  });

  return (
    <tr
      key={id}
      ref={drop}
      data-testid={`playlist-row-${id}`}
    >
      <td title={format.playlist.typeDescr(type)}>
        <i className={format.playlist.typeIcon(type)} />
      </td>
      <td>
        <a
          className="d-sm-none" // Only on mobile
          href={`#/plan/playlist/${id};${moment(fromDate).format('YYYY-MM-DD')}`}
          data-testid="mobile-plan-playlist-sidebar-toggle"
        >
          {item.get('name')}
        </a>
        <a
          className="d-none d-sm-inline" // Larger than mobile
          href={`#/plan/playlist/${id}`}
          data-testid="plan-playlist-sidebar-toggle"
        >
          {item.get('name')}
        </a>
      </td>
      <td>
        <div className="d-flex">
          {renderTemplates(item, {
            templates,
            hoverRow: isOver,
            planLocked,
            canDrop,
            dropMedia: handleDrop,
          })}
        </div>
      </td>
      {cancan.can('read', PLANNING_TIMEPLAN) && !planLocked && (
        <td>
          <button
            data-testid="copy-last-week"
            type="button"
            className="btn p-1 border"
            onClick={copyLastWeek}
          >
            <i
              className="icon-share"
              title="Copy & paste last week"
            />
          </button>
        </td>
      )}
    </tr>
  );
};

export default compose(
  connect(null,
    (dispatch, props) => ({
      copyLastWeek() {
        dispatch(copyFromInterval(
          moment(props.fromDate).add(-1, 'w'),
          moment(props.toDate).add(-1, 'w'),
          (date) => moment(date).add(1, 'w'),
          props.item.get('id'),
        ));
      },

      dropMedia(mediaIds, playlistId, template) {
        const mediaObjectIds = !Array.isArray(mediaIds)
          ? [mediaIds]
          : mediaIds;
        const templates = template ? [template] : props.templates;
        const plId = playlistId || props.item.get('id');
        return dispatch(bulkPlanMedia(mediaObjectIds, plId, templates));
      },
    })),
)(PlaylistRow);
