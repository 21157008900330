import React, { useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useHistory, withRouter } from 'react-router-dom';
import cancan from 'js/system/cancan';
// Actions
import * as MsgActions from 'js/react/actions/message-actions';
import { PLACES, PLANNING_LONG_TERM, PLANNING_TIMEPLAN } from 'constants/permissions';
import { useRequestWithPromise } from 'src/core/api';
import { createPlaylist } from 'src/entities/playlists';

const defaultType = () => {
  const hasTimeplan = cancan.can('read', PLANNING_TIMEPLAN);
  const hasLongTermPlan = cancan.can('read', PLANNING_LONG_TERM);
  const hasPlaces = cancan.can('read', PLACES);
  const types = { repeating: ['default'] };

  if (hasPlaces) {
    types.direct = ['area', 'stop'];
    types.repeating.push('area');
    types.repeating.push('line');
    types.repeating.push('stop');
  }

  if (hasTimeplan || hasLongTermPlan) {
    types.repeating.push('filler');
  }

  return types;
};

const AddPlaylist = ({ onAdd }) => {
  const { formatMessage } = useIntl();
  const { dispatch } = useRequestWithPromise();
  const [values, setValues] = useState({
    name: '',
    type: 'repeating',
    subType: 'default',
    maxTime: 60,
    description: formatMessage({
      id: 'views.planning.playlists.changeThisDescription',
    }),
    repeat: true,
    category: 'none',
  });
  const mainTypes = defaultType();
  const subTypes = mainTypes[values.type];
  const history = useHistory();

  useEffect(() => {
    setValues((vals) => ({
      ...vals,
      subType: mainTypes[vals.type]?.[0],
    }));
  }, [values.type]);

  /**
  * * Called when a place field is changed
  * @param {event} event Default javascript click event
  */
  const handleFieldChange = (event) => {
    const field = event.target;

    // Make sure we have changed the value
    if (field.value !== values[field.name]) {
      setValues({
        ...values,
        [field.name]: field.value,
      });
    }
  };

  /**
  * Handles the saving of data
  * @param {event} event Default javascript click event
  */
  const handleSave = (event) => {
    event.preventDefault();
    let valid = true;

    const valuesToSave = { ...values };
    const {
      name, type, subType,
    } = valuesToSave;

    // Basic validation
    if (name.length < 2) {
      MsgActions.error('Playlist name needs to be minimum 2 characters');
      valid = false;
    }

    if (!mainTypes[type]) {
      MsgActions.error('Incorrect playlist type');
      valid = false;
    }

    if (!mainTypes[type].includes(subType)) {
      MsgActions.error('Incorrect playlist sub type');
      valid = false;
    }

    if (subType === 'filler') {
      valuesToSave.maxTime = 600;
    }

    // Create stuff
    if (valid) {
      dispatch(createPlaylist(values)).then(({ payload }) => {
        MsgActions.success(`Playlist ${name} added`);
        history.push(`/playlists/${payload.id}/edit`);
        onAdd(payload);
      });
    }
  };

  return (
    <div className="p-3 border-top" data-testid="PlaylistListAdd">
      <table className="settings w-75 mb-3">
        <tbody>
          <tr>
            <td className="w110">
              <FormattedMessage id="common.name" />
              {': '}
            </td>
            <td>
              <input
                data-testid="PlaylistInputName"
                type="text"
                name="name"
                className="form-control mb-1"
                value={values.name}
                onChange={handleFieldChange}
              />
            </td>
          </tr>
          <tr>
            <td>
              <FormattedMessage id="common.type" />
              {': '}
            </td>
            <td>
              <select
                type="select"
                name="type"
                className="form-control mb-1"
                disabled={mainTypes.size === 1}
                value={values.type}
                onChange={handleFieldChange}
              >
                {Object.keys(mainTypes).map((type) => {
                  return (
                    <option value={type} key={type}>
                      {formatMessage({
                        id: `common.${type}`,
                      })}
                    </option>
                  );
                })}
              </select>
            </td>
          </tr>
          <tr>
            <td>
              <FormattedMessage id="common.subtype" />
              {': '}
            </td>
            <td>
              <select
                type="select"
                name="subType"
                className="form-control mb-1"
                disabled={subTypes.size === 1}
                value={values.subType}
                onChange={handleFieldChange}
              >
                {subTypes.map((subtype) => (
                  <option value={subtype} key={subtype}>
                    {formatMessage({
                      id: `views.playlists.${subtype}`,
                    })}
                  </option>
                ))}
              </select>
            </td>
          </tr>
        </tbody>
      </table>
      <button
        data-testid="PlaylistSaveButton"
        className="btn btn-primary"
        onClick={handleSave}
        type="button"
      >
        <FormattedMessage id="common.save" />
      </button>
    </div>
  );
};

export default withRouter(AddPlaylist);
